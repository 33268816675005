import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-hidden-data',
  styleUrl: 'x-hidden-data.sass',
})
export class XHiddenData {
  @Prop() visible: boolean;

  render() {
    return (
      <Host>
        <div class="slot">
          <slot></slot>
        </div>
        <svg viewBox="0 0 72 8">
          <circle cx="4" />
          <circle cx="20" />
          <circle cx="36" />
          <circle cx="52" />
          <circle cx="68" />
        </svg>
      </Host>
    );
  }
}
