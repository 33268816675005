x-hidden-data
  font-size: 0
  line-height: 0
  svg
    display: none
    fill: var(--button-static-dark-grey)
    width: 72px
    height: 8px
    circle
      cy: 4
      r: 4
    &:not([visible])
      .slot
        display: none
      svg
        display: block
